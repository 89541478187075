import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layouts/layout"
import * as myModule from "../components/dynamic"
import Seo from "../components/Seo/seo"
function ourTeamPage({ data }) {
  const wpData = data.wpPage.sections.blocks
  const seo = data?.wpPage?.seo
  return (
    <Layout>
      <Seo
        title={seo.title}
        description={seo.metaDesc}
        featuredImage={
          seo.opengraphImage?.localFile.childImageSharp.gatsbyImageData
        }
      />
      {wpData.map(row => {
        // assign component math with name
        const Component = myModule[row.fieldGroupName.split("_").slice(-1)[0]]
        return <Component key={row.fieldGroupName} {...row} />
      })}
    </Layout>
  )
}

export default ourTeamPage

export const query = graphql`
  query {
    wpPage(id: { eq: "cG9zdDoyOTY=" }) {
      seo {
        ...SeoData
      }
      sections {
        blocks {
          ... on WpPage_Sections_Blocks_PageHeader {
            fieldGroupName
            title
            subtitle
            box {
              title
              link {
                title
                url
              }
            }
          }
          ... on WpPage_Sections_Blocks_TwoColumnsWithImage {
            fieldGroupName
            col1 {
              subtitle
              title
              content
            }
            col2 {
              title
              content
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on WpPage_Sections_Blocks_Team {
            fieldGroupName
            title
            team {
              ... on WpLoanOfficer {
                uri
                title
                content
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
                officers {
                  position
                  location
                  nmls
                  hixonLendingNmls
                  licensedIn
                }
              }
            }
          }
        }
      }
    }
  }
`
